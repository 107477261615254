"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Task = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Task extends Base_1.Base {
    constructor(data, olMap) {
        super('tasks', 16);
        this.name = '';
        this.changeId = '';
        this.type = '';
        this.description = '';
        this.index = 0;
        this.completed = 0;
        this.tested = 0;
        this.validated = false;
        this.creator = null;
        this.agents = [];
        this.validator = null;
        this.files = [];
        this.followers = [];
        this.follower_ids = [];
        this.agent_ids = [];
        (0, Common_1.setObjectProperties)(this, data, olMap, Task);
    }
    toJSON() {
        let r = super.toJSON();
        r.follower_ids = [];
        ['agents', 'creator', 'validator', 'followers', 'files'].forEach(p => {
            var _a, _b;
            r[p] = (0, index_1.toMinJSON)(this, p);
            if (p === 'followers') {
                r.follower_ids = r.follower_ids.concat((_a = r[p]) === null || _a === void 0 ? void 0 : _a.map(a => a.id));
            }
            if (p === 'agents') {
                r.agent_ids = (_b = r[p]) === null || _b === void 0 ? void 0 : _b.map(a => a.id);
                r.follower_ids = r.follower_ids.concat(r.agent_ids);
            }
        });
        r.validated = this.validated || false;
        r.index = this.index || 0;
        r.last_date = this.last_date || r.date;
        r.tested = this.tested || 0;
        r.completed = this.completed || 0;
        r.changeId = this.changeId || '';
        return r;
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        Object.assign(r, {
            name: this.name || '',
            completed: this.completed || 0
        });
        return r;
    }
}
exports.Task = Task;
index_1.olm.tasks = (ref, map) => {
    return new Task(ref, map);
};
