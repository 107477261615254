"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInvoiceOrQuoteID = exports.timeSince = exports.clean = exports.round = exports.formatDate = exports.prettifyPhone = exports.formatCurrency = exports.PROVINCES = exports.STATES = exports.setObjectProperties = exports.getSID = exports.isValidEmail = exports.isValidPhone = exports.stackTrace = void 0;
const date_fns_1 = require("date-fns");
const index_1 = require("../index");
function stackTrace() {
    var err = new Error();
    return err.stack;
}
exports.stackTrace = stackTrace;
function isValidPhone(phoneNumber) {
    let res = '';
    if (phoneNumber) {
        let phone = phoneNumber.replace(/\D/g, '');
        if (phone && (phone.length === 10 || phone.length === 7)) {
            res = phone;
        }
    }
    return res;
}
exports.isValidPhone = isValidPhone;
function isValidEmail(email) {
    if (email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
            return true;
        }
    }
    return false;
}
exports.isValidEmail = isValidEmail;
function getSID(length, date) {
    let id = '';
    let aChars = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'y', 'x', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    length = (length && length > 0) ? length : 6;
    for (let i = 0; i < length; i++) {
        id += aChars[Math.floor(Math.random() * aChars.length)];
    }
    if (date) {
        return `${Date.now()}-${id}`;
    }
    else {
        return id;
    }
}
exports.getSID = getSID;
/**
 * This sets properties on a Base object.
 * @param obj - the object instance
 * @param data - the data used to populate object properties. This can be a plain, data map or a DocumentSnapshot from firestore
 * @param olm - the object loader map appropriate for the context.
 */
function setObjectProperties(obj, data, olm, klass) {
    if (data !== 'PROTO' && klass) {
        obj._p = Reflect.construct(klass, ['PROTO']);
    }
    if (data) {
        if (data.ref && data.data instanceof Function) {
            obj._docRef = data.ref;
            let id = data.id;
            obj._exists = data.exists instanceof Function ? data.exists() : data.exists || false;
            data = data.data() || {};
            data.id = id;
        }
        else if (data._docRef) {
            obj._docRef = data._docRef;
            obj._exists = data.exists instanceof Function ? data.exists() : data.exists || false;
        }
        if (data) {
            olm = olm || index_1.olm;
            Object.keys(obj).forEach(p => {
                var _a, _b;
                if (data[p] !== undefined) {
                    if (obj[p] instanceof index_1.DimensionArray) {
                        obj[p] = new index_1.DimensionArray(data.dimensions);
                    }
                    else if (obj[p] instanceof index_1.ChildArray) {
                        obj[p] = new index_1.ChildArray();
                        if ((_a = data[p]) === null || _a === void 0 ? void 0 : _a.length) {
                            obj[p] = data[p].map(i => {
                                if (i._type && olm) {
                                    return olm[i._type](i, olm);
                                }
                                else {
                                    return i;
                                }
                            });
                        }
                    }
                    else {
                        obj[p] = data[p];
                        if (obj[p] instanceof Array) {
                            obj[p] = obj[p].map(i => {
                                var _a;
                                if (i._type && olm[i._type]) {
                                    i = olm[i._type](i, olm);
                                }
                                else if (i._type && i.id) {
                                    // let err = new Error();
                                    // err.stack
                                    console.warn(`No type loader for type ${i._type} on ${((_a = obj._docRef) === null || _a === void 0 ? void 0 : _a.path) || obj.id}`);
                                }
                                return i;
                            });
                        }
                        if (obj[p] && obj[p]._type && olm) {
                            if (olm[obj[p]._type]) {
                                obj[p] = olm[obj[p]._type](obj[p], olm);
                            }
                            else if (obj[p]._type && obj[p].id) {
                                // let err = new Error();
                                // err.stack
                                console.warn(`No type loader for type ${obj[p]._type} on ${((_b = obj._docRef) === null || _b === void 0 ? void 0 : _b.path) || obj.id}`);
                            }
                        }
                    }
                }
            });
        }
    }
    return data;
}
exports.setObjectProperties = setObjectProperties;
exports.STATES = [
    { value: 'AK', label: 'Alaska' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DC', label: 'District of Columbia' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'GU', label: 'Guam' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'IA', label: 'Iowa' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MD', label: 'Maryland' },
    { value: 'ME', label: 'Maine' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MT', label: 'Montana' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: ' North Dakota' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NY', label: 'New York' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VA', label: 'Virginia' },
    { value: 'VI', label: 'Virgin Islands' },
    { value: 'VT', label: 'Vermont' },
    { value: 'WA', label: 'Washington' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WY', label: 'Wyoming' }
];
exports.PROVINCES = [
    { value: 'AB', label: 'Alberta', country: 'CA' },
    { value: 'BC', label: 'British Columbia', country: 'CA' },
    { value: 'MB', label: 'Manitoba', country: 'CA' },
    { value: 'NS', label: 'Nova Scotia', country: 'CA' },
    { value: 'NB', label: 'New Brunswick', country: 'CA' },
    { value: 'NF', label: 'Newfoundland and Labrador', country: 'CA' },
    { value: 'NT', label: 'Northwest Territory', country: 'CA' },
    { value: 'NU', label: 'Nunavut', country: 'CA' },
    { value: 'ON', label: 'Ontario', country: 'CA' },
    { value: 'PE', label: 'Prince Edward Island', country: 'CA' },
    { value: 'QC', label: 'Quebec', country: 'CA' },
    { value: 'SK', label: 'Saskatchewan', country: 'CA' },
    { value: 'YT', label: 'Yukon Territory', country: 'CA' }
];
function formatCurrency(n) {
    if (n !== undefined && isNaN(n) === false) {
        let currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });
        return currencyFormatter.format(n);
    }
    else {
        return '';
    }
}
exports.formatCurrency = formatCurrency;
function prettifyPhone(sNum) {
    if (sNum) {
        let aSupportedCountries = [
            { nPhone: 1, sCode: 'us', sName: 'United States', sFULL: '(\\+)?(1)(\\d{10})', sAREA: '(\\d{10})', sPrettify: '(\\d{3})(\\d{3})(\\d{4})' }
        ];
        sNum = sNum.toString();
        sNum = sNum.replace(/\D/g, '').replace(/ /g, '');
        for (let n = 0; n < aSupportedCountries.length; n++) {
            let hCountry = aSupportedCountries[n];
            if (sNum && sNum.match(new RegExp(hCountry.sFULL))) {
                sNum = sNum.substring(1);
            }
            else if (!sNum.match(new RegExp(hCountry.sAREA))) {
                sNum = null;
            }
            if (sNum) {
                let sPretty = sNum;
                let aMatches = sNum.match(new RegExp(hCountry.sPrettify));
                if (aMatches)
                    if (hCountry.sCode == 'us') {
                        sPretty = aMatches[1] + '-' + aMatches[2] + '-' + aMatches[3];
                        return sPretty;
                    }
            }
        }
    }
    else {
        sNum = '';
    }
    return sNum;
}
exports.prettifyPhone = prettifyPhone;
function formatDate(time, f) {
    if (time && time instanceof Date) {
        return (0, date_fns_1.format)(time, f || 'EEE, MMM do h:mm a');
    }
    else if (time) {
        return (0, date_fns_1.format)(new Date(time), f || 'EEE, MMM do h:mm a');
    }
    else {
        return '';
    }
}
exports.formatDate = formatDate;
function round(value, decimals) {
    // @ts-ignore
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}
exports.round = round;
function clean(o, n) {
    n = n || 0;
    if (n < 3 && o && typeof o === 'object' && Object.keys(o) && Object.keys(o).length) {
        Object.keys(o).forEach((subprop) => {
            if (o[subprop] === undefined) {
                delete o[subprop];
            }
            else {
                o[subprop] = clean(o[subprop], n + 1);
            }
        });
    }
    return o;
}
exports.clean = clean;
function timeSince(date) {
    let seconds = Math.floor((Date.now() - date) / 1000);
    let divisors = [31536000, 2592000, 86400, 3600, 60, 1];
    let description = ["yr", "mon", "d", "h", "min", "sec"];
    let result = [];
    let interval = seconds;
    for (let i = 0; i < divisors.length; i++) {
        interval = Math.floor(seconds / divisors[i]);
        if (interval > 1 && !result.length) {
            result.push(interval + " " + description[i]);
        }
        seconds -= interval * divisors[i];
    }
    return result.join(" ");
}
exports.timeSince = timeSince;
const goodChars = [
    'C', 'D', 'E', 'G', 'H',
    'I', 'J', 'K', 'L', 'O',
    'Q', 'R', 'T', 'U', 'W',
    'X', 'Y', 'Z', 'c', 'd',
    'e', 'g', 'h', 'i', 'j',
    'k', 'l', 'o', 'q', 'r',
    't', 'u', 'w', 'x', 'y', 'z'
];
const allChars = ['2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'y', 'x', 'z'];
function getGoodLetters(n) {
    let id = '';
    n = (n && n > 0) ? n : 6;
    for (let i = 0; i < n; i++) {
        id += goodChars[Math.floor(Math.random() * goodChars.length)];
    }
    return id;
}
function getHourChar(hour) {
    hour = Number(hour);
    if (hour < 7) {
        return 'C';
    }
    else {
        let now = new Date().getTime();
        if (2 % now) {
            return goodChars[hour - 7];
        }
        else {
            return goodChars[hour - 7].toLowerCase();
        }
    }
}
function getMinuteChar(minute) {
    return allChars[Number(minute)];
}
function getInvoiceOrQuoteID(isQuote, date) {
    let now = date || new Date();
    return `${isQuote ? 'Q-' : ''}${(0, date_fns_1.format)(now, 'yyMMdd')}${getHourChar((0, date_fns_1.format)(now, 'H'))}${getMinuteChar((0, date_fns_1.format)(now, 'm'))}${getGoodLetters(2)}`;
}
exports.getInvoiceOrQuoteID = getInvoiceOrQuoteID;
