import {
    Component, Input, Output, EventEmitter,
    ViewChild, ViewContainerRef, AfterViewInit, ElementRef
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {IAlert, PageService} from '../_services/page.service';
import {BehaviorSubject} from 'rxjs';
import {getSID} from '@nxt/model-core';
import {Router, RouterModule} from '@angular/router';
import {AvatarComponent} from '../avatars/avatar.component';
import {DomSanitizer} from '@angular/platform-browser';
import {IconsComponent} from '../icons/icons.component';
import {LoadableModule} from '../flex/dynamic.loader.component';
import {take, takeUntil} from 'rxjs/operators';
import {OnDestroyPage} from '../_inherited/ondestroy.page';


@Component({
    selector: 'simple-component-loader',
    standalone: true,
    imports: [],
    template: `
        <ng-container #targetContainer></ng-container>
    `
})
export class SimpleComponentLoader implements AfterViewInit {
    @ViewChild('targetContainer', {read: ViewContainerRef}) public targetContainer: ViewContainerRef;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() public notification: IAlert;

    ngAfterViewInit() {
        if (this.notification?.component) {
            let ref: any = this.targetContainer.createComponent<any>(this.notification.component);
            ref.instance?.onClose?.pipe(take(1))
                .subscribe(e => {
                    this.onClose.emit(e);
                });
            if (this.notification.onLoaded) {
                this.notification.onLoaded(ref.instance);
            }
        }
    }

}


@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule, AvatarComponent, IconsComponent, LoadableModule,
        SimpleComponentLoader
    ],
    selector: 'notification-dialog',
    template: `
        <div aria-live="assertive" style="z-index:10000"
             class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
            <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
                <ng-container *ngFor="let notification of notifications; let i = index;">
                    <div [class]="notification ? 'transition ease-in duration-100 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 translate-y-0 opacity-100 sm:translate-x-0' : 'transition ease-in duration-100 translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'">
                    <div class="p-4">
                        <div class="flex items-start">
                            <div class="flex-shrink-0">
                                <div class="flex flex-col relative">
                                    <avatar *ngIf="avatar" [user]="avatar" size="10"></avatar>
                                    <img [src]="icon" *ngIf="icon" class="h-8 w-8 bg-gray-100 rounded-full ring-1 ring-white"/>
                                </div>
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                                <p class="flex text-sm font-medium text-gray-900" *ngIf="notification?.title">
                                    {{ notification?.title }}
                                    <span class="ml-2" [innerHTML]="notification.payload?.data?.emoji"></span>
                                </p>
                                <p class="mt-1 text-sm text-gray-500" *ngIf="notification?.message">
                                    {{ notification?.message }}
                                </p>
                                <p class="mt-1 text-sm text-gray-500" *ngIf="html" [innerHTML]="html"></p>
                                <simple-component-loader [notification]="notification" (onClose)="close(i)"></simple-component-loader>
                            </div>
                            <div class="ml-4 flex flex-shrink-0">
                                <button (click)="close(i)"
                                        class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-dark-500 focus:ring-offset-2">
                                    <span class="sr-only">Close</span>
                                    <icon name="heroicon-outline-x" class="h-5 w-5"></icon>
                                </button>
                            </div>
                        </div>
                        <div class="flex place-content-end w-full mt-3" *ngIf="notification?.buttons?.length">
                            <button style="margin-right: 12px"
                                    [class]="button.class?.default ? button.class.default : ''"
                                    *ngFor="let button of notification?.buttons"
                                    (click)="clickButton(button, i)">
                                {{ button.label }}
                            </button>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    `
})
export class NotificationDialog {
    @ViewChild('modalContainer', {static: false, read: ViewContainerRef}) modalContainer: ViewContainerRef;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    timers$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    notifications: IAlert[] = [];
    avatar: any;
    icon: string;
    emoji: any;
    html: any;

    constructor(
        public pSvc: PageService,
        private router: Router,
        private sanitizer: DomSanitizer
    ) {

        this.timers$.subscribe(
            id => {
                if (id) {
                    setTimeout(() => {
                        let n: number = this.notifications.findIndex(n => n['id'] === id);
                        if (n > -1 && this.notifications[n]) {
                            this.notifications.splice(n,1);
                        }
                    }, 7000);
                }
            }
        )

        // Make sure the notification goes away on its own within 8 seconds.
        this.pSvc.notification$
            .subscribe(notification => {
                if (notification) {
                    this.avatar = null;
                    this.icon = null;
                    notification['id'] = getSID(5);
                    if (notification.payload?.data?.action && notification.payload?.data?.url) {
                        notification.buttons = [
                            {
                                label: notification.payload?.data?.action,
                                click: () => {
                                    if (notification.payload?.data?.url?.match(window.location.origin)) {
                                        this.router.navigate([notification.payload.data.url.replace(window.location.origin, '')])
                                    } else {
                                        window.open(notification.payload?.data?.url, '_self');
                                    }

                                }
                            }
                        ]
                    }
                    if (notification.payload?.data?.avatar) {
                        this.avatar = {
                            photoURL: notification.payload?.data.avatar,
                            first_name: notification.payload?.data.first_name,
                            last_name: notification.payload?.data.last_name
                        }
                    } else if (notification.payload?.data?.first_name||notification.payload?.data?.last_name) {
                        this.avatar = {
                            first_name: notification.payload?.data?.first_name||'',
                            last_name: notification.payload?.data?.last_name||''
                        }
                    }
                    if (notification.payload?.data?.icon || notification.payload?.notification?.icon) {
                        this.icon = notification.payload.data.icon || notification.payload?.notification?.icon;
                        console.log('icon', this.icon);
                    }
                    if (!notification.component) {
                        this.notifications.push(notification);
                    } else {
                        this.notifications = [notification];
                    }

                    if (notification.timeout !== 0) {
                        this.timers$.next(notification['id']);
                    }
                    this.html = (notification.html) ? this.sanitizer.bypassSecurityTrustHtml(notification.html) : null;
                } else if (notification === null) {
                    this.notifications = [];
                }
            })
    }

    close(i: number) {
        this.onClose.emit();
        this.notifications.splice(i, 1);
    }

    clickButton(button: any, i: number) {
        this.close(i);
        if (button.click) {
            button.click();
        }
    }
}
