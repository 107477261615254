import {Injectable} from '@angular/core';

import {ClientService} from './client.service';

@Injectable()
export class LocalStorageService {
    globalState: any = localStorage?.getItem('gS') ? JSON.parse(localStorage?.getItem('gS')) : {};
    l: any = {};

    get localState(): any {
        let name_key = this.cSvc.client$.getValue()?.name_key || this.cSvc.c$.getValue().name_key;
        this.l[name_key] = localStorage?.getItem(name_key) ? JSON.parse(localStorage?.getItem(name_key)) : {};
        return this.l[name_key] || {};
    }

    constructor(private cSvc: ClientService) {

    }

    propagate(conf: any, data: any) {
        if (!conf) {
            conf = {};
        }
        if (data) {
            Object.keys(data).forEach((prop: string) => {
                if (!conf[prop] || data[prop] !== undefined) {
                    conf[prop] = data[prop];
                }
                if (typeof data[prop] === 'object') {
                    this.propagate(conf[prop], data[prop]);
                }
            });
        }
    }

    saveState(path?: any, value?: any) {
        if (path) {
            let l: any = this.localState||{};
            if (value instanceof Array) {
                l[path] = value;
            } else if (typeof value === 'object') {
                l[path] = l[path] || {};
                this.propagate(l[path], value);
            } else if (value !== undefined) {
                l[path] = value;
            } else if (l && l[path]) {
                delete l[path];
            }
            this.l[this.cSvc.name_key] = l;
        }
        localStorage?.setItem(this.cSvc.name_key, JSON.stringify(this.l[this.cSvc.name_key]));
    }

    saveGState(path?: any, value?: any) {
        if (path) {
            if (!this.globalState[path]) {
                this.globalState[path] = {};
            }
            if (typeof value === 'object') {
                this.propagate(this.globalState[path], value);
            } else {
                this.globalState[path] = value;
            }
        }
        localStorage?.setItem('gS', JSON.stringify(this.globalState));
    }

    async flushState() {
        await localStorage?.clear();
    }

}


