"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShortUrl = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class ShortUrl extends Base_1.Base {
    constructor(data, olMap) {
        super('short_urls', 14);
        this.longUrl = null;
        (0, Common_1.setObjectProperties)(this, data, olMap, ShortUrl);
    }
}
exports.ShortUrl = ShortUrl;
index_1.olm.short_urls = (ref, map) => {
    return new ShortUrl(ref, map);
};
