"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Document = void 0;
const Common_1 = require("./Common");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Document extends Base_1.Base {
    constructor(data, olMap) {
        super('documents', 12);
        this.key = '';
        this.url = '';
        this.name = '';
        this.template_id = '';
        this.type = '';
        this.stage = '';
        this.subject = '';
        this.sms = '';
        this.data_argument = '';
        this.data_loader_fn = '';
        this.data_example = {};
        this.email = '';
        this.email_type = '';
        this.description = '';
        this.html = '';
        this.sitemap = null;
        this.metadata = {};
        this.allow_note = false;
        this.followers = [];
        this.roles = [];
        this.skipTracking = false;
        (0, Common_1.setObjectProperties)(this, data, olMap, Document);
    }
}
exports.Document = Document;
index_1.olm.documents = (ref, map) => {
    return new Document(ref, map);
};
